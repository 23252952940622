export const CREATE_TOKEN = "CREATE_TOKEN";


export const CREDIENTIAL_SUCCESS = "CREDIENTIAL_SUCCESS";
export const CREDIENTIAL_ERROR = "CREDIENTIAL_ERROR";

export const DEACTIVATE_SUCCESS = "DEACTIVATE_SUCCESS";
export const DEACTIVATE_ERROR = "DEACTIVATE_ERROR";

export const SET_LOGS_SUCCESS = "SET_LOGS_SUCCESS";
export const SET_LOGS_ERROR = "SET_LOGS_ERROR";


export const SET_KEYS = "SET_KEYS";


