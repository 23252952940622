const REACT_APP_API_BASE_URL =
  process.env.REACT_APP_ENV === "production"
    ? process.env.REACT_APP_API_URL_PROD
    : process.env.REACT_APP_API_URL_dev;

const SEARCH_DOMAIN = `import fetch from "node-fetch";

import { Headers } from "node-fetch";

const myHeaders = new Headers()
myHeaders.append('api-key','Apikey')
myHeaders.append('Content-Type','application/json')

const searchBody = {
  domainName:"test1.qie"
}

var Options = {
  method: 'POST',
  headers: myHeaders,
  body: JSON.stringify(searchBody)
};

fetch("${REACT_APP_API_BASE_URL}/domains/search", Options)
  .then(response => response.json())
  .then(result => {console.log(result)
  .catch(error => console.log('error', error));
`;

const MY_DOMAINS = `import fetch from "node-fetch";
import { Headers } from "node-fetch";

const myHeaders = new Headers()
myHeaders.append('api-key','Apikey')
myHeaders.append('Content-Type','application/json')


var Option = {
  method: 'GET',
  headers: myHeaders
};

fetch("${REACT_APP_API_BASE_URL}/domains?address=0x", Option)
  .then(response => response.json())
  .then(result => console.log(result))
  .catch(error => console.log('error', error));
`;

const MINT_DOMAIN = `import fetch from "node-fetch";
import { Headers } from "node-fetch";

const myHeaders = new Headers()
myHeaders.append('api-key','Apikey')
myHeaders.append('Content-Type','application/json')


const mintDomainBody = {
  "domainName":"mint-demo2.qie",
  "walletAddress":"0x44...83a",
  "transactionHash":"0xc..5b"
}

const Options = {
  method: 'POST',
  headers: myHeaders,
  body: JSON.stringify(mintDomainBody)
}

fetch("${REACT_APP_API_BASE_URL}/domains/mint-and-verify", Options)
  .then(response => response.json())
  .then(result => console.log(result))
  .catch(error => console.log('error', error));`;

const SEARCH_DOMAIN_URL = "https://sdk-live.hovr.site/api/v1/domains/search";
const MY_DOMAINS_URL = "https://sdk-live.hovr.site/api/v1/domains?address";

const MINT_DOMAIN_URL =
  "https://sdk-live.hovr.site/api/v1/domains/mint-and-verify";

module.exports = {
  SEARCH_DOMAIN,
  MY_DOMAINS,
  MINT_DOMAIN,

  SEARCH_DOMAIN_URL,
  MY_DOMAINS_URL,
  MINT_DOMAIN_URL,
};
