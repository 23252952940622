import { toast } from "react-toastify";
import {
  clearAllKeysFromLocalStorage,
  getAuthToken,
  getDataFromLocalStorage,
  removeToken,
  setAuthToken,
  setKeyToLocalStorage,
} from "../../utils/common/localStorege";
import {
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  NAVIGATE_TO_OTP_SCREEN,
  NAVIGATE_TO_OTP_SCREEN_FALSE,
  REGISTRATION_ERROR,
  REGISTRATION_SUCCESS,
  SET_LOADING_FALSE,
  SET_LOADING_TRUE,
} from "../constants/auth.constannts";
import axiosinstance from "../../utils/axios/index";

export const isuserLogiIn = () => {
  return async (dispatch) => {
    const token = getAuthToken();
    const user = JSON.parse(getDataFromLocalStorage("Qieuser"));
    if (token && user) {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { token, isAuthencated: true, user },
      });
    } else {
      if (token) {
        dispatch(logoutUserAction());
      }
      dispatch({ type: LOGIN_ERROR, payload: { error: "failed to Login" } });
    }
  };
};

const readReferalCookie = () => {
  const cookie = document.cookie.match(/(^| )REFERRAL=([^;]+)/);
  return cookie ? cookie[2] : null;
};

export const registerUserAction = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_LOADING_TRUE });
      // access key payload and call api
      const resp = await axiosinstance.post("/enterprise/signup", data);

      toast.success(resp.data.message);
      dispatch({
        type: REGISTRATION_SUCCESS,
        payload: { successMessage: resp.data.message },
      });
    } catch (error) {
      // dispatch({ type: SET_LOADING_FALSE, payload: { errorMessage: error.response.message } });
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        dispatch({
          type: REGISTRATION_ERROR,
          payload: { errorMessage: error.response.data.message },
        });
        toast.error(error.response.data.message);
      } else {
        dispatch({
          type: REGISTRATION_ERROR,
          payload: { errorMessage: "Something went wrong" },
        });
        toast.error("Something went wrong");
      }
    } finally {
      dispatch({ type: SET_LOADING_FALSE });
    }
  };
};

export const resentOtp = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_LOADING_TRUE });
      // access key payload and call api
      const resp = await axiosinstance.post(
        "/admins/resend-verify-email",
        data
      );
      toast.success(resp.data.message);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went wrong");
      }
    } finally {
      dispatch({ type: SET_LOADING_FALSE });
    }
  };
};

export const sentPasswordLink = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_LOADING_TRUE });
      // access key payload and call api
      const resp = await axiosinstance.post("/admins/forgot-password", data);
      toast.success(resp.data.message);
      return true;
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went wrong");
      }
    } finally {
      dispatch({ type: SET_LOADING_FALSE });
    }
  };
};

export const changePassword = (data, token) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_LOADING_TRUE });
      // access key payload and call api
      const resp = await axiosinstance.post(
        `/admins/reset-password?token=${token}`,
        data
      );
      toast.success(resp.data.message);
      console.log(resp);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went wrong");
      }
    } finally {
      dispatch({ type: SET_LOADING_FALSE });
    }
  };
};

export const verifyOtp = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_LOADING_TRUE });
      // access key payload and call api
      const resp = await axiosinstance.post("/admins/verify-account", data);
      toast.success(resp.data.message);
      dispatch({ type: NAVIGATE_TO_OTP_SCREEN_FALSE });
      return true;
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went wrong");
      }
    } finally {
      dispatch({ type: SET_LOADING_FALSE });
    }
  };
};

export const markOtpScreenFalse = () => {
  return async (dispatch) => {
    dispatch({ type: NAVIGATE_TO_OTP_SCREEN_FALSE });
  };
};

export const loginUserAction = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_LOADING_TRUE });
      const resp = await axiosinstance.post("/admins/login", data);
      // if email not verifies

      toast.success(resp.data.message);
      if (resp.data.data && resp.data.data.isEmailVerified === false) {
        dispatch({ type: NAVIGATE_TO_OTP_SCREEN });
      } else {
        const { user, token } = resp.data.data;
        setKeyToLocalStorage("Qieuser", JSON.stringify(user));
        setAuthToken(token);
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { user: user, isAuthencated: true, token: token },
        });
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        dispatch({
          type: LOGIN_ERROR,
          payload: { errorMessage: error.response.data.message },
        });
        toast.error(error.response.data.message);
      } else {
        dispatch({
          type: LOGIN_ERROR,
          payload: { errorMessage: "Something went wrong" },
        });
        toast.error("Something went wrong");
      }
    } finally {
      dispatch({ type: SET_LOADING_FALSE });
    }
  };
};

export const logoutUserAction = (msg) => async (dispatch) => {
  removeToken();
  clearAllKeysFromLocalStorage();
  if (msg === "Logged out successfully.") {
    toast.success("Logged out successfully.");
  }
  dispatch({ type: LOGOUT_SUCCESS });
};
