import React from "react";
import Endpoints from "../views/Endpoints";

let routes = [
  // {
  //   path: "/documentation",
  //   component: Documentation,
  //   routeGuard: "NoAuthGuard"
  // },
  // {
  //   path: "/get-started",
  //   component: Endpoints,
  //   routeGuard: "NoAuthGuard"
  // },
  // {
  //   path: "/authentication",
  //   component: Authentication,
  //   routeGuard: "NoAuthGuard"
  // },
  {
    path: "/endpoints",
    component: Endpoints,
    routeGuard: "NoAuthGuard",
  },
  // {
  //   path: "/client-libraries",
  //   component: Clientlibrary,
  //   routeGuard: "NoAuthGuard"
  // },
  // {
  //   path: "/client-libraries/node",
  //   component: Clientlibrary,
  //   routeGuard: "NoAuthGuard"
  // }
];
export default routes;
