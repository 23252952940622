import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Container } from "shards-react";
import { DefaultLayout } from '../../layouts';
import PageTitle from '../../components/common/PageTitle';
import EditIcon from '@mui/icons-material/Edit';
import './style.css';
import { getApiLogsAction } from '../../redux/actions/credentials.action';

const ViewLogs = () => {
  const dispatch = useDispatch();
  const { viewApiLogs } = useSelector(state => state.Credential);

  useEffect(() => {
    getApiLogs();
  }, []);

  const getApiLogs = () => {
    dispatch(getApiLogsAction());
    // console.log(viewApiLogs);
  }

  return (
    <DefaultLayout>
      <Container fluid className="main-content-container px-4">
        <PageTitle sm="5" title="Profile" className="page-heading mt-5" />

        <div className="row">
          <div className="col-lg-12 mx-auto mt-3">
            <div className="card card-small mb-4">
              <div className="card-header border-bottom">
                {/* <button className='btn btn-primary'><span><EditIcon /></span> Edit</button> */}
              </div>
              <ul className="list-group list-group-flush">
                <li className="list-group-item p-3">
                  <div className="row">
                    <div className="col">
                      <form>
                        <div className="form-row">
                          <div className="form-group col-md-5 flex inputBoxMain">
                            <label htmlFor="feFirstName">First Name</label>
                            <input type="text" className="form-control" id="feFirstName" placeholder="First Name" value="" />
                          </div>

                          <div className="form-group col-md-5 flex inputBoxMain">
                            <label htmlFor="feLastName">Last Name</label>
                            <input type="text" className="form-control" id="feLastName" placeholder="Last Name" value="" />
                          </div>
                        </div>

                        {/* <div className="form-row">
                        <div className="form-group col-md-6">
                          <label htmlFor="feEmailAddress">Email</label>
                          <input type="email" className="form-control" id="feEmailAddress" placeholder="Email" value="sierra@example.com" /> </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="fePassword">Password</label>
                          <input type="password" className="form-control" id="fePassword" placeholder="Password" /> </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="feInputAddress">Address</label>
                        <input type="text" className="form-control" id="feInputAddress" placeholder="1234 Main St" />
                      </div> */}

                        <button type="submit" className="btn btn-accent">Edit details</button>
                      </form>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

      </Container>
    </DefaultLayout>
  )
};


export default ViewLogs;
