import React from "react";
import { Link } from "react-router-dom";
import "./footer.css";
import GitHubIcon from "@mui/icons-material/GitHub";
import TwitterIcon from "@mui/icons-material/Twitter";
import FaceBookIcon from "@mui/icons-material/Facebook";
import YoutubeIcon from "@mui/icons-material/YouTube";

export default function Footer() {
  return (
    <footer className="footer">
      <div className="flex-container-footer">
        <div className="footer-menu first-footer-menu">
          <h6 className="footer-item-heading">API</h6>

          <div className="footer-item">
            <Link className="footer-itemLink" to="/register">
              Get started
            </Link>
          </div>

          <div className="footer-item">
            <Link className="footer-itemLink" to="/register">
              Documentation
            </Link>
          </div>
        </div>

        <div className="footer-menu second-footer-menu">
          <h6 className="footer-item-heading">Company</h6>

          <div className="footer-item">
            <a
              className="footer-itemLink"
              target="_blank"
              href="https://hovr.site/privacy-policy"
            >
              Privacy policy
            </a>
          </div>

          <div className="footer-item">
            <a
              className="footer-itemLink"
              target="_blank"
              href="https://hovr.site/terms-conditions"
            >
              Terms of service
            </a>
          </div>
        </div>

        <div className="footer-menu third-footer-menu">
          <h6 className="footer-item-heading">Support</h6>

          <div className="footer-item">
            <a
              className="footer-itemLink"
              target="_blank"
              href="https://hovr.site/about"
            >
              About Us
            </a>
          </div>

          <div className="footer-item">
            <a
              className="footer-itemLink"
              target="_blank"
              href="https://twitter.com/HovrSite"
            >
              Twitter <TwitterIcon className="boot-icon" />
            </a>
          </div>

          <div className="footer-item">
            <a
              className="footer-itemLink"
              target="_blank"
              href="https://www.facebook.com/hovr.site/"
            >
              Facebook <FaceBookIcon className="boot-icon" />
            </a>
          </div>
          <div className="footer-item">
            <a
              className="footer-itemLink"
              target="_blank"
              href="https://www.youtube.com/watch?v=CMqRqd97rQg"
            >
              Youtube <YoutubeIcon className="boot-icon" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
