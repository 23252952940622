const navItems = () => {
  return [
    // {
    //   title: "Overview",
    //   htmlBefore: '<i class="material-icons">home</i>',
    //   to: "/dashboard",
    // },
    {
      title: "API keys",
      htmlBefore: '<i class="material-icons">key</i>',
      to: "/credentials",
    },
    {
      title: "Documentation",
      htmlBefore: '<i class="material-icons">dock</i>',
      to: "/endpoints",
    },
    // {
    //   title: "Profile",
    //   htmlBefore: '<i class="material-icons">person</i>',
    //   to: "/profile",
    // },
    // {
    //   title: "Settings",
    //   htmlBefore: '<i class="material-icons">settings</i>',
    //   to: "/comingsoon",
    // },
  ];
};

export default navItems;
