import { toast } from 'react-toastify';
import { SET_LOADING_FALSE, SET_LOADING_TRUE } from '../constants/auth.constannts';
import store from '../index';
import axiosinstance from '../../utils/axios';
import { CREDIENTIAL_ERROR, CREDIENTIAL_SUCCESS, DEACTIVATE_ERROR, DEACTIVATE_SUCCESS, SET_KEYS, SET_LOGS_ERROR, SET_LOGS_SUCCESS } from '../constants/credentials.constants';

export const genrateAndRegenrateKeysAction = () => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_TRUE });
    try {

      // access key payload and call api
      const { data } = await axiosinstance.post("/api-keys");
      dispatch({ type: CREDIENTIAL_SUCCESS, payload: { successMessage: data.message, keyData: data.data } });

    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        dispatch({ type: CREDIENTIAL_ERROR, payload: { errorMessage: "Something went wrong" } });
        toast.error(error.response.data.message);
      } else {
        dispatch({ type: CREDIENTIAL_ERROR, payload: { errorMessage: "Something went wrong" } });
        toast.error("Something went wrong");
      }
    } finally {
      dispatch({ type: SET_LOADING_FALSE });
    }
  }
}

export const deactivateKeysAction = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_TRUE });
    try {
      // access key payload and call api
      const { data } = await axiosinstance.put(`/api-keys/${id}/deactivate`);
      dispatch({ type: DEACTIVATE_SUCCESS, payload: { successMessage: data.message, keyData: null } });
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        dispatch({ type: DEACTIVATE_ERROR, payload: { errorMessage: "Something went wrong" } });
        toast.error(error.response.data.message);
      } else {
        dispatch({ type: DEACTIVATE_ERROR, payload: { errorMessage: "Something went wrong" } });
        toast.error("Something went wrong");
      }
    } finally {
      dispatch({ type: SET_LOADING_FALSE });
    }
  }
}




export const getKeysAction = () => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_TRUE });
    try {

      // access key payload and call api
      const { data } = await axiosinstance.get("/api-keys/active");
      dispatch({ type: SET_KEYS, payload: { keyData: data.data } });

      // let { Auth: { user } } = store.getState();
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        dispatch({ type: CREDIENTIAL_ERROR, payload: { errorMessage: "Something went wrong" } });
        toast.error(error.response.data.message);
      } else {
        dispatch({ type: CREDIENTIAL_ERROR, payload: { errorMessage: "Something went wrong" } });
        toast.error("Something went wrong");
      }
    } finally {
      dispatch({ type: SET_LOADING_FALSE });
    }
  }
}



export const getApiLogsAction = () => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_TRUE });
    try {

      // access key payload and call api
      const { data } = await axiosinstance.get("/api-keys");
      dispatch({ type: SET_LOGS_SUCCESS, payload: { data: data.data } });

    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        dispatch({ type: SET_LOGS_ERROR, payload: { errorMessage: "Something went wrong" } });
        toast.error(error.response.data.message);
      } else {
        dispatch({ type: SET_LOGS_ERROR, payload: { errorMessage: "Something went wrong" } });
        toast.error("Something went wrong");
      }
    } finally {
      dispatch({ type: SET_LOADING_FALSE });
    }
  }
}
