export const setAuthToken = (token) => {
    localStorage.setItem('Qietoken', token)
}

export const removeToken = () => {
    localStorage.removeItem('Qietoken')
}


export const getAuthToken = () => {
    return localStorage.getItem('Qietoken')
}


export const setKeyToLocalStorage = (key, data) => {
    localStorage.setItem(key, data)
}

export const getDataFromLocalStorage = (key) => {
    return localStorage.getItem(key)
}

export const removeKeyFromLocalStorage = (key) => {
    localStorage.removeItem(key);
}


export const clearAllKeysFromLocalStorage = () => {
    localStorage.clear();
}
