import React from 'react';
import Otp from '../views/Otp/Otp';

const Home = React.lazy(() => import('../views/Home'));
const Login = React.lazy(() => import('../views/Login'));
const Register = React.lazy(() => import('../views/Register'));
const ResetPassword = React.lazy(() => import('../views/Resetpassword'));
const ChangePassword = React.lazy(() => import('../views/Changepassword'));

// Route Views


const routes = [
  {
    path: "/",
    exact: true,
    component: Home,
    routeGuard: 'NoAuthGuard'
  },
  {
    path: "/login",
    exact: true,
    component: Login,
    routeGuard: 'Guestroute'
  },
  {
    path: "/otp",
    exact: true,
    component: Otp,
    routeGuard: 'Guestroute'
  },
  {
    path: "/register",
    component: Register,
    exact: true,
    routeGuard: 'Guestroute'
  },
  {
    path: "/forgotpassword",
    exact: true,
    component: ResetPassword,
    routeGuard: 'Guestroute'
  },

  {
    path: "/change-password",
    exact: true,
    component: ChangePassword,
    routeGuard: 'Guestroute'
  },
];


export default routes;
