import { setKeyToLocalStorage } from '../../utils/common/localStorege';
import {
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  LOGOUT_ERROR,
  LOGOUT_START,
  LOGOUT_SUCCESS,
  MENU_OVERLAY_OFF,
  MENU_OVERLAY_ON,
  ACTIVE_LINK,
  SET_LOADING_TRUE,
  SET_LOADING_FALSE,
  REGISTRATION_SUCCESS,
  REGISTRATION_ERROR,
  CLEAR_MESSAGES,
  NAVIGATE_TO_OTP_SCREEN,
  NAVIGATE_TO_OTP_SCREEN_FALSE,
  SENT_PASSWORD_RESET_LINK
} from "../constants/auth.constannts";

const initState = {
  loading: false,
  errorMessage: null,
  successMessage: null,
  isAuthencated: false,
  isNavigateToOtpScreen: false,
  token: null,
  user: null,
  MenuOver: "-1000px",
  activeLink: "Get_started"
};

const AuthReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        token: payload.token,
        user: payload.user,
        // user:payload.user,
        isAuthencated: payload.isAuthencated
      };
    case LOGIN_ERROR:
      return { ...state, loading: false, isAuthencated: false, errorMessage: payload.errorMessage };

    case NAVIGATE_TO_OTP_SCREEN:
      return { ...state, loading: false, isNavigateToOtpScreen: !state.isNavigateToOtpScreen };
    case NAVIGATE_TO_OTP_SCREEN_FALSE:
      return { ...state, loading: false, isNavigateToOtpScreen: false };

    case REGISTRATION_SUCCESS:
      return { ...state, loading: false, successMessage: payload.successMessage, isNavigateToOtpScreen: true };
    case REGISTRATION_ERROR:
      return { ...state, loading: false, errorMessage: payload.errorMessage };

    case SENT_PASSWORD_RESET_LINK:
      return { ...state, loading: false, successMessage: payload.successMessage, isNavigateToOtpScreen: true };

    case LOGOUT_START:
      return { ...state, loading: true };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthencated: false,
        user: null,
        token: null
      };
    case LOGOUT_ERROR:
      return { ...state, loading: false };


    case MENU_OVERLAY_ON:
      return { ...state, MenuOver: "0px" };
    case MENU_OVERLAY_OFF:
      return { ...state, MenuOver: "-1000px" };
    case ACTIVE_LINK:
      return { ...state, activeLink: payload };

    case CLEAR_MESSAGES:
      return { ...state, loading: false, errorMessage: null, successMessage: null };


    case SET_LOADING_TRUE:
      return { ...state, loading: true };
    case SET_LOADING_FALSE:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default AuthReducer;
