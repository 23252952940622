import React from "react";
import {
  Form,
} from "shards-react";

const fun = () => (
  <Form className="main-navbar__search w-100 d-none d-md-flex d-lg-flex"></Form>
);

export default fun;
