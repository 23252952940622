import { CREATE_TOKEN, CREDIENTIAL_ERROR, CREDIENTIAL_SUCCESS, DEACTIVATE_ERROR, DEACTIVATE_SUCCESS, SET_KEYS, SET_LOGS_ERROR, SET_LOGS_SUCCESS } from '../constants/credentials.constants';

const initState = {
  keyData: null,
  successMessage: null,
  errorMessage: null,
  viewApiLogs: []
};

const CredientialReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case CREATE_TOKEN:
      return { ...state, loading: false, isAuthencated: false, errorMessage: payload.errorMessage };
    case CREDIENTIAL_SUCCESS:
      return { ...state, loading: false, ...payload };
    case CREDIENTIAL_ERROR:
      return { ...state, loading: false, errorMessage: payload.errorMessage };

    case DEACTIVATE_SUCCESS:
      return { ...state, loading: false, keyData: payload.keyData };
    case DEACTIVATE_ERROR:
      return { ...state, loading: false, errorMessage: payload.errorMessage };

    case SET_LOGS_SUCCESS:
      return { ...state, loading: false, viewApiLogs: payload.data };
    case SET_LOGS_ERROR:
      return { ...state, loading: false, errorMessage: payload.errorMessage };

    case SET_KEYS:
      return { ...state, loading: false, keyData: payload.keyData };
    default:
      return state;
  }
}
export default CredientialReducer;
