import React from "react";
import Editor from "../../components/Editor/Editor";
import "./home.css";
import "./api.css";
import {
  MINT_DOMAIN,
  MINT_DOMAIN_URL,
  MY_DOMAINS,
  MY_DOMAINS_URL,
  SEARCH_DOMAIN,
  SEARCH_DOMAIN_URL,
} from "./ApisData";

export default function MainBox() {
  return (
    <div className="main-box">
      <div className="start-para">
        <h4>QIE API Endpoints</h4>
        <p>
          Our official QIE Domain APIs are a set of simple HTTP REST APIs for
          Domain Minting, Searching, Verify transactions on QIE blockchain.
        </p>
      </div>

      <div className="apiMainBox">
        <div className="apileft">
          <div className="urls">
            <span className="title">POST</span>
            <span className="urldesc">{SEARCH_DOMAIN_URL}</span>
          </div>

          <p className="urlCard">{SEARCH_DOMAIN_URL}</p>

          <div className="dataBody">
            <div className="request_body">
              <div className="bodytitle">
                <span className="bodybody">HEADERS</span>
              </div>

              <div className="bodytablestyle">
                <div className="keystyle">accessId</div>
                <div className="keyvalue">accessId</div>
              </div>
              <div className="bodytablestyle">
                <div className="keystyle">api-key</div>
                <div className="keyvalue">Apikey</div>
              </div>

              <div className="bodytablestyle">
                <div className="keystyle">Content-Type</div>
                <div className="keyvalue">application/json</div>
              </div>

              <div className="bodytitle mar-5">
                <span className="bodybody">BODY</span>
                <span className="bodyurlcoded">urlencoded</span>
              </div>
              <div className="bodytablestyle">
                <div className="keystyle">domainName</div>
                <div className="keyvalue">test1.qie</div>
              </div>
            </div>
          </div>
        </div>
        <div className="apiright">
          <Editor code={SEARCH_DOMAIN} />
        </div>
      </div>

      <div className="apiMainBox">
        <div className="apileft">
          <div className="urls">
            <span className="title">POST</span>
            <span className="urldesc">{MINT_DOMAIN_URL}</span>
          </div>

          <p className="urlCard">{MINT_DOMAIN_URL}</p>

          <div className="dataBody">
            <div className="request_body">
              <div className="bodytitle">
                <span className="bodybody">BODY</span>
                <span className="bodyurlcoded">urlencoded</span>
              </div>
              <div className="bodytablestyle">
                <div className="keystyle">domainName</div>
                <div className="keyvalue">example.qie</div>
              </div>

              <div className="bodytablestyle">
                <div className="keystyle">domainId</div>
                <div className="keyvalue">656d</div>
              </div>

              <div className="bodytablestyle">
                <div className="keystyle">transactionHash</div>
                <div className="keyvalue">0xc34...75455c</div>
              </div>

              <div className="bodytablestyle">
                <div className="keystyle">walletAddress</div>
                <div className="keyvalue">0xc34...7555c</div>
              </div>
            </div>
          </div>
        </div>
        <div className="apiright">
          <Editor code={MINT_DOMAIN} />
        </div>
      </div>

      <div className="apiMainBox">
        <div className="apileft">
          <div className="urls">
            <span className="title">GET</span>
            <span className="urldesc">{MY_DOMAINS_URL}</span>
          </div>

          <p className="urlCard">{MY_DOMAINS_URL}</p>

          {/* <div className="dataBody">
            <div className="request_body">

              <div className="bodytitle">
                <span className="bodybody">BODY</span>
                <span className="bodyurlcoded">urlencoded</span>
              </div>
              <div className="bodytablestyle">
                <div className="keystyle">domainName</div>
                <div className="keyvalue">example.qie</div>
              </div>

              <div className="bodytablestyle">
                <div className="keystyle">domainId</div>
                <div className="keyvalue">656d</div>
              </div>

              <div className="bodytablestyle">
                <div className="keystyle">transactionHash</div>
                <div className="keyvalue">0xc34...75455c</div>
              </div>

              <div className="bodytablestyle">
                <div className="keystyle">walletAddress</div>
                <div className="keyvalue">0xc34...7555c</div>
              </div>
            </div>
          </div> */}
        </div>
        <div className="apiright">
          <Editor code={MY_DOMAINS} />
        </div>
      </div>
    </div>
  );
}
