import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "../Login/login.css";
import Navbar from "../../components/Navbar/Navbar";
import OtpInput from "react-otp-input";
import { toast } from "react-toastify";
import {
  markOtpScreenFalse,
  resentOtp,
  verifyOtp,
} from "../../redux/actions/auth.Actions";
import Preloader from "../../assets/images/login_preloader.gif";
import "./otp.css";

export default function Otp(props) {
  const dispatch = useDispatch();
  const { loading, isNavigateToOtpScreen } = useSelector((state) => state.Auth);

  useEffect(() => {
    dispatch(markOtpScreenFalse());
  }, []);

  // const { loading } = useSelector(state => state.Auth);
  const [otp, setOtp] = useState("");
  const { state } = useLocation();

  if (!state || !state.email) {
    props.history.push("/login");
    return;
  }

  const onSubmit = async (event) => {
    event.preventDefault();
    if (otp.length < 6) {
      toast.error("Invalid otp");
      return;
    }

    const data = {
      email: state.email,
      otp,
    };
    const resp = await dispatch(verifyOtp(data));
    if (resp) {
      props.history.push("/login");
    }
  };

  const resentOtpClick = (async) => {
    const data = {
      email: state.email,
      otp,
    };
    dispatch(resentOtp(data));
  };

  return (
    <Fragment>
      <Navbar />

      <div className="container">
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ marginTop: "8rem" }}
        >
          <div className="box-mainauth col-md-6">
            {/* <Link to="/">
              <img src={logo} alt="logo" className="logo" srcSet="" />
            </Link> */}
            <h3 className="title">Verify your account</h3>

            <div className="otpboxmain">
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                renderSeparator={<span>-</span>}
                renderInput={(props) => <input {...props} />}
              />
            </div>

            <button
              disabled={loading}
              onClick={onSubmit}
              className=" mt-5 otpButton"
            >
              {loading ? (
                <>
                  Please wait <img alt="laoder" src={Preloader} />
                </>
              ) : (
                <span>Verify it</span>
              )}
            </button>

            <div className="mt-4 text-center">
              <span className="text-center">Didn't receive an otp? </span>
              <span
                className="link"
                style={{ cursor: "pointer" }}
                onClick={resentOtpClick}
              >
                Resend OTP?
              </span>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
