export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const NAVIGATE_TO_OTP_SCREEN = "NAVIGATE_TO_OTP_SCREEN";
export const NAVIGATE_TO_OTP_SCREEN_FALSE = "NAVIGATE_TO_OTP_SCREEN_FALSE"

export const LOGOUT_START = "LOGOUT_START";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_ERROR = "LOGOUT_ERROR";
export const SET_LOADING_TRUE = "SET_LOADING_TRUE";
export const SET_LOADING_FALSE = "SET_LOADING_FALSE";

export const REGISTRATION_SUCCESS = "REGISTRATION_SUCCESS";
export const REGISTRATION_ERROR = "REGISTRATION_ERROR";

export const CLEAR_MESSAGES = "CLEAR_MESSAGES";


export const MENU_OVERLAY_ON = 'MENU_OVERLAY_ON';
export const MENU_OVERLAY_OFF = 'MENU_OVERLAY_OFF';
export const ACTIVE_LINK = 'ACTIVE_LINK';



export const SENT_PASSWORD_RESET_LINK = "SENT_PASSWORD_RESET_LINK"

